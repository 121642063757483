import React from 'react'
import Layout from '../components/layout'
import MyMatches from '../components/my-matches'

export default({ data }) => (
  <Layout>
    <h2 className="has-text-centered title is-2">My Matches</h2>
    <MyMatches></MyMatches>
  </Layout>
)
