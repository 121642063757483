import React from 'react'
import axios from 'axios'
import * as storage from '../utils/storage'
import EachResult from './each-result'

class MyMatches extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      deleted_matches: new Set(),
    }
  }

  deleteMatch = user => {
    if (
      window.confirm(
        'Are you sure you want to delete ' +
          user.first_name +
          '? This cannot be undone!'
      )
    ) {
      const filtered_users = this.state.users.filter(u => {
        return user.id != u.id
      })

      this.setState({ users: filtered_users })

      let currentUser = storage.getItem('user')
      currentUser = currentUser ? JSON.parse(currentUser) : currentUser

      axios
        .post(
          `${process.env.API_BASE_URL}/api/delete-match/${currentUser.id}/${
            user.id
          }`,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + storage.getItem('access_token'),
            },
          }
        )
        .catch(err => console.log(err))
    }
  }

  componentDidMount() {
    let currentUser = storage.getItem('user')
    currentUser = currentUser ? JSON.parse(currentUser) : currentUser
    axios
      .post(
        `${process.env.API_BASE_URL}/api/filter-matchs/${currentUser.id}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + storage.getItem('access_token'),
          },
        }
      )
      .then(res => {
        this.setState({ deleted_matches: new Set(res.data.data) })

        axios
          .post(
            `${process.env.API_BASE_URL}/graphql`,
            {
              query: `query {
        me {
          matches(first: 1000) {
            edges {
              node {
                id
                email
                first_name
                last_name
                age
                mobile
                location
                photo_url
                public_contacts
              }
            }
          }
      }
      }`,
            },
            {
              headers: {
                Authorization: 'Bearer ' + storage.getItem('access_token'),
              },
            }
          )
          .then(res => {
            this.setState({
              users: res.data.data.me.matches.edges
                .map(edge => edge.node)
                .filter(user => {
                  return !this.state.deleted_matches.has(parseInt(user.id))
                }),
            })
          })
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  } // componentDidMount

  render() {
    return (
      <div className="past-events result">
        {this.state.users.map((user, i) => {
          return (
            <EachResult deleteMatch={this.deleteMatch} key={i} user={user} />
          )
        })}
      </div>
    )
  }
}

export default MyMatches
